<template lang="pug">
.orders
  h2 Minhas compras

  RemotePaginatedList(
    name="orders",
    :search="search",
    :orderBy="orderBy",
    :itemsPerPage="6",
    :breakpoints="{ 769: { itemsPerPage: 8 }, 1025: { itemsPerPage: 12 } }",
  )
    template(v-slot="{ items: orders }")
      .orders-list

        NuxtLink.order(v-for="order in orders", :to="{ name: 'order', params: { orderId: order.id } }")

          .order-details

            h4.order-title Compra # {{ order.id }}

            .order-status Pagamento pendente

            .order-date {{ localizedDate(order.createdAt) }}

            .order-total R$ {{  centsToString(order.total) }}

          .order-items
            .order-thumbnail(v-if="orderThumbnail(order)")
              .image
                CdnImage(
                  :src="orderThumbnail(order)",
                  :width="136",
                  :height="136",
                  :quality="75"
                )

          .order-actions
            Button(
              type="text",
              label="Ver detalhes",
            )

    template(v-slot:no-result)
      .no-orders
        | Hmm... você ainda não possui nenhuma compra
</template>
<script lang="ts" setup>
import type { OrderSummary } from '~/services/ecommerce/orderService'

import OrderService from '~/services/ecommerce/orderService'

const search = OrderService.searchSelfOrders
const orderBy = {
  field: "createdAt",
  direction: "DESC"
}

const orderThumbnail = (order: OrderSummary): string | null => {
  if (order.items.length === 0) return null

  return order.items[0].thumbnail?.fileName ?? null
}
</script>
<style lang="sass" scoped>
@import '~/assets/styles/mixins'
@import '~/assets/styles/variables'

.orders
  width: 100%
  max-width: 1400px
  margin: 0 auto
  padding: 0 16px

  .orders-list
    display: grid
    grid-template-columns: repeat(3, 1fr)
    gap: 16px
    margin-top: 16px

    @include tablet
      grid-template-columns: repeat(2, 1fr)

    @include phablet
      grid-template-columns: 1fr

    .order
      display: grid
      grid-template-areas: "details items" "actions items"
      grid-template-columns: 1fr auto
      grid-template-rows: 1fr auto
      background-color: $beige
      border-radius: 8px

      .order-details
        grid-area: details
        display: flex
        flex-direction: column
        justify-content: flex-start
        gap: 4px
        padding: 16px 16px 0 16px

        h4.order-title
          grid-area: title
          margin: 0

        .order-date
          grid-area: date
          color: #333
          font-size: 14px

        .order-status
          grid-area: status
          color: $magenta
          font-weight: bold

        .order-total
          grid-area: total
          font-weight: bold
          margin-top: 8px

      .order-items
        grid-area: items
        width: 100%
        flex-grow: 1
        width: 144px
        height: 152px
        padding: 8px 8px 8px 0
        overflow: hidden

        .order-thumbnail
          display: inline-block

          .image
            display: flex
            justify-content: center
            align-items: center
            background-color: #fff
            border-radius: 4px
            overflow: hidden

      .order-actions
        grid-area: actions
        width: 100%


  .no-orders
    width: 100%
    text-align: center
    font-size: 18px
    color: #AAA
</style>